var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { attrs: { color: "transparent", "max-width": "600px" } },
    [
      _c("confirmation-values-container", {
        staticClass: "mb-5",
        attrs: { items: _vm.valueItems },
      }),
      _c("confirmation-summary-block", {
        attrs: { items: _vm.summaryItems },
        scopedSlots: _vm._u([
          {
            key: "rightColItem0",
            fn: function () {
              return [
                _c("div", { staticClass: "mew-body" }, [
                  _vm._v(" " + _vm._s(_vm.feeFormatted) + " "),
                  _c("span", { staticClass: "greyPrimary--text" }, [
                    _vm._v(_vm._s(_vm.network.type.currencyName) + "/"),
                  ]),
                  _vm._v(" ~" + _vm._s(_vm.txFeeUsd) + " "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "rightColItem1",
            fn: function () {
              return [
                _c("div", { staticClass: "mew-body" }, [
                  _vm._v(" " + _vm._s(_vm.totalFee) + " "),
                  _c("span", { staticClass: "greyPrimary--text" }, [
                    _vm._v(_vm._s(_vm.network.type.currencyName) + "/"),
                  ]),
                  _vm._v(" ~" + _vm._s(_vm.totalFeeUSD) + " "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }